import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import { useTranslation } from 'tools/i18n';
import { makeStyles } from '@material-ui/styles';
import theme from '../theme';
import Link from 'next/link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import EmailValidator from 'email-validator';
import { Alert } from '@material-ui/lab';
import urlCorrection from '../../tools/urlCorrection';
import { TrackingContext } from '../providers/tracking';
import ChevronRight from "public/images/icons/chevron_right_light.svg";
import useSessionStore from '@services/stores/SessionStore';
import useUserStore from '@services/stores/UserStore';
const useStyles = makeStyles(
    {
        wrapper: {
            padding: '40px 0 0',
            backgroundColor: theme.palette.primary.background,
            '&.compact': {
                marginTop: 0
            },
            '.xmas &': {
                background: theme.palette.primary.main,
            },
            '.blackfriday &': {
                background: theme.palette.header.background,
                padding: '80px 0 0',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '80px 0 0',
            }
        },
        container: {
            [theme.breakpoints.up('sm')]: {
                padding: 0
            }
        },
        title: {
            marginBottom: 20,
            fontSize: 14,
            fontWeight: 300,
            color: theme.palette.primary.color,
            whiteSpace: 'pre',
            [theme.breakpoints.up('sm')]: {
                height: 70,
                marginBottom: 0
            }
        },
        containerBlock: {
            padding: 7.5,
            marginBottom: 40,
            backgroundColor: theme.palette.primary.main,
            '.blackfriday &': {
                backgroundColor: theme.palette.header.background
            },
            color: theme.palette.footer.color,
            [theme.breakpoints.down('xs')]: {
                margin: 0,
                width: '100%'
            },
            [theme.breakpoints.up('sm')]: {
                padding: '60px 40px',
                margin: '0 20px',
                width: 'auto'
            },
            [theme.breakpoints.up('lg')]: {
                padding: '60px 40px',
                margin: '0 20px',
                width: 'auto'
            },
        },
        links: {
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                marginTop: 0
            },
            [theme.breakpoints.up('md')]: {
                width: '75%',
                paddingLeft: '60px !important',
                '.compact &': {
                    width: '50%'
                }
            }
        },
        linksGroup: {
            '.compact & ul': {
                columnCount: 2
            },
            '.compact &:not(:last-of-type)': {
                display: 'none'
            },
            [theme.breakpoints.up('sm')]: {
                '&.desktopOnly': {
                    display: 'none'
                }
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center'
            }
        },
        linksTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 0,
            width: '100%',
            fontSize: 16,
            lineHeight: '28px',
            fontWeight: 400,
            fontFamily: 'Chronicle, serif',
            '& svg': {
                marginRight: -10,
                fill: theme.palette.primary.background,
                transition: 'transform 0.25s ease'
            },
            '&.open': {
                paddingBottom: 0,
                '& svg': {
                    transform: 'rotate(-90deg)'
                }
            },
            [theme.breakpoints.up('md')]: {
                fontSize: 18,
                marginBottom: 0,
                '& svg': {
                    display: 'none'
                }
            }
        },
        linksItem: {
            padding: 0,
            listStyle: 'none',
            textAlign: 'left',
            '& a': {
                position: 'relative',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '40px',
                color: 'inherit',
                fontFamily: theme.typography.headerFont,
                textDecoration: 'none',
                '&:after': {
                    content: '""',
                    width: '100%',
                    position: 'absolute',
                    bottom: -3,
                    right: 0,
                    left: 0,
                    height: 1,
                    transform: 'scaleX(0)',
                    transition: 'transform .2s ease',
                    backgroundColor: theme.palette.primary.background
                },
                '&:hover:after': {
                    transform: 'scaleX(1)'
                }
            },
            '&.socialMedia': {
                display: 'flex',
                flexWrap: 'wrap',
                gap: 20,
                position: 'relative',
                '& a': {
                    display: 'flex',
                    alignItems: 'center',
                    '.xmas &': {
                        color: '#fff',
                    },
                    '.blackfriday &': {
                        color: '#fff',
                    },
                    '& img': {
                        marginRight: 5
                    }
                }
            }
        },
        showMore: {
            display: 'flex',
            justifyContent: 'center',
            listStyle: 'none',
            cursor: 'pointer',
            '& svg': {
                marginLeft: -30,
                fill: theme.palette.primary.background
            }
        },
        socialMediaLinks: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column'
            }
        },
        socialMediaContainer: {
            marginTop: 20,
            [theme.breakpoints.down('sm')]: {
                '& .desktopOnly': {
                    display: 'none !important'
                }
            }
        },
        copyright: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '10px 0 30px',
            '& p': {
                fontSize: 12,
                lineHeight: 1.75,
                color: '#999'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '20px 20px 30px'
            }
        },
        newsletter: {
            display: 'flex',
            flexDirection: 'column',
        },
        newsletterTitle: {
            margin: 0,
            fontSize: 20,
            fontWeight: 400,
            fontFamily: theme.typography.headerFont,
            '& a': {
                color: 'inherit',
                textDecoration: 'none'
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        newsletterSubtitle: {
            margin: '5px 0 20px',
            fontSize: 14,
            fontWeight: 400,
            fontFamily: theme.typography.headerFont,
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        newsletterActions: {
            position: 'relative',
            display: 'flex',
            marginTop: 30,
            [theme.breakpoints.down('xs')]: {
                marginTop: 0,
                width: '100%',
                '&.showCountrySelector': {

                }
            }
        },
        newsletterSelectWrapper: {
            display: 'none',
            marginRight: 10,
            color: theme.palette.primary.background,
            '& fieldset': {
                borderWidth: 0,
                borderBottomWidth: 1,
                borderColor: theme.palette.primary.background,
                borderRadius: 0
            },
            '& svg': {
                color: theme.palette.primary.background
            },
            '&:hover': {
                '& fieldset': {
                    borderColor: `${theme.palette.primary.background} !important`
                }
            }
        },
        newsletterSelect: {
            padding: '15.5px 14px 15.5px 0',
            fontFamily: theme.typography.headerFont
        },
        newsletterInputWrapper: {
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                '.showCountrySelector &': {
                    flexGrow: 1
                }
            }
        },
        newsletterInput: {
            paddingLeft: 0,
            borderRadius: 0,
            color: theme.palette.primary.background,
            backgroundColor: 'transparent',
            fontFamily: theme.typography.headerFont,
            '&:hover': {
                backgroundColor: 'transparent'
            },
            [theme.breakpoints.up('sm')]: {
                minWidth: 200
            }
        },
        newsletterInputRoot: {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '&::before, &::after': {
                borderColor: `${theme.palette.primary.background} !important`
            }
        },
        newsletterSignup: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginLeft: 10,
            padding: '6px 6px',
            minWidth: 0,
            fontSize: 12,
            fontWeight: 600,
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            borderRadius: 0,
            '& svg': {
                fill: theme.palette.primary.background
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.background,
                '& svg': {
                    fill: '#888'
                }
            },
            '.showCountrySelector &': {
                [theme.breakpoints.down('xs')]: {

                }
            },
            [theme.breakpoints.down('xs')]: {
                marginRight: -18,
                '& svg': {
                    width: 32
                }
            }
        },
        newsletterInputLabel: {
            left: -12,
            fontSize: 14,
            fontWeight: 400,
            fontFamily: theme.typography.headerFont,
            color: `${theme.palette.primary.background} !important`
        },
        newsletterInputLabelActive: {
            left: 0
        },
        newsletterIntegrity: {
            marginTop: 4,
            fontSize: 12,
            fontFamily: theme.typography.headerFont,
            '& a': {
                color: 'inherit'
            }
        },
        accordion: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            color: 'inherit',
            [theme.breakpoints.up('sm')]: {
                '& > div': {
                    minHeight: '0px !important',
                    height: 'auto !important',
                    visibility: 'visible'
                }
            }
        },
        accordionSummary: {
            padding: 0,
            minHeight: 0,
            '&.expanded': {
                minHeight: 0
            },
            '& .content': {
                width: '100%',
                margin: 0
            },
            [theme.breakpoints.down('sm')]: {
                padding: '12px 0',
                borderBottom: '1px solid rgba(255,255,255,0.5)',
                '.desktopOnly &': {
                    borderBottom: 'none'
                }
            },
        },
        accordionDetails: {
            padding: 0,
            [theme.breakpoints.up('md')]: {
                marginTop: 4
            }
        },
        linebreak: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'unset',
            }
        }
    },
    { name: 'Footer' }
);

export default function Footer({ menu }) {
    const router = useRouter();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const myNewportService = useUserStore((state) => state.service);
    const { getCountries } = useSessionStore((state) => state.methods);
    const {
        token,
        salesChannel,
        config,
        countries,
        geoIpCountry
    } = useSessionStore((state) => state.session);
    const { actions: trackingActions } = useContext(TrackingContext);
    const { t } = useTranslation(['general', 'popup']);
    const [isCheckout, setIsCheckout] = useState(false);
    const [newsletterEmail, setNewsletterEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showCountrySelector, setShowCountrySelector] = useState(false);
    const [country, setCountry] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState({});
    const [expandLinks, setExpandLinks]= useState({})
    const salesChannelId = salesChannel.id;

    const classes = useStyles();

    useEffect(() => {
        if(token && config.language.locale === 'en') {
            getCountries();
        }
    }, [token]);

    useEffect(() => {
        if(countries && countries.length > 1) {
            setShowCountrySelector(true);
            getInitialSalesChannelCountry();
        }
    }, [countries]);

    useEffect(() => {
        if (router.asPath === '/checkout') {
            setIsCheckout(true);
        } else {
            setIsCheckout(false);
        }
    }, [router.asPath]);

    function getInitialSalesChannelCountry() {
        if(geoIpCountry) {
            let correctGeoIPCountry = countries.filter((country) => {
                return country.iso === geoIpCountry;
            });

            if(correctGeoIPCountry && correctGeoIPCountry.length > 0) {
                setCountry(correctGeoIPCountry[0].iso);
            }
        }
    }

    function renderLinkItem(item, index) {
        let isExternal = item;

        return (
            <li className={classes.linksItem} key={index}>
                {isExternal ? <a
                        href={urlCorrection(item.url)}
                        onClick={() =>
                            trackingActions.click({
                                type: 'footer_link',
                                text: item.name,
                                identifier: urlCorrection(item.url)
                            })
                        }
                    >
                        {item.name}
                    </a> :
                    <Link href={urlCorrection(item.url)}>
                        <a
                            onClick={() =>
                                trackingActions.click({
                                    type: 'footer_link',
                                    text: item.name,
                                    identifier: urlCorrection(item.url)
                                })
                            }
                        >
                            {item.name}
                        </a>
                    </Link>
                }
            </li>
        );
    }

    function getFormattedLanguageCode() {
        let languageCode = config.language.languageCode;

        return languageCode.toLowerCase().replace('-', '_');
    }

    function validateEmail(email) {
        return EmailValidator.validate(email);
    }

    async function newsletterSubmit() {
        if (!validateEmail(newsletterEmail)) {
            return;
        }

        let subscriberCountry = countries.length > 1 ? country : config?.language?.languageCode.split('-')[1];

        try {
            setLoading(true);

            let payload = {
                email: newsletterEmail,
                country: subscriberCountry || geoIpCountry || null,
                store: salesChannelId || null,
                source: 'footer',
            };

            const discoveryKey = myNewportService.getLocalDiscoveryKey();
            if (discoveryKey) {
                payload.discovery_key = discoveryKey;
            }

            let response = await fetch('/api/voyado/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            let registrationData = await response.json();

            if (registrationData.status === 'error') {
                if (registrationData.message.indexOf('Member exists') >= 0) {
                    setError(t('errors:signup-already-exists'));
                } else if (registrationData.message.indexOf('Request is invalid') >= 0) {
                    setError(t('errors:signup-request-invalid'));
                } else {
                    setError(registrationData.message);
                }
            } else {
                setError(false);
                setNewsletterEmail('');
                setSuccess(true);

                trackingActions.click({
                    type: 'footer_newsletter',
                    text: t('popup:intro-subscribe'),
                    identifier: 'signup_success'
                });
            }
            setLoading(false);
        } catch (e) {
            setError(t('errors:signup-general-error'));

            setLoading(false);
        }
    }

    return !isCheckout ? (
		<div className={classes.wrapper}>
			<Container className={classes.container}>
				<Grid spacing={6} className={classes.containerBlock} container>
					<Grid xs={12} sm={6} md={3} className={classes.newsletter} item>
						<h3
							className={classes.newsletterTitle}
							dangerouslySetInnerHTML={{ __html: t('popup:newsletter-signup-title') }}
						/>
						<p className={classes.newsletterSubtitle}>{t('popup:newsletter-signup-text')}</p>

						{error ? (
							<Grid item>
								<Alert severity="error" style={{ marginBottom: 10 }}>
									<span dangerouslySetInnerHTML={{ __html: error }} />
								</Alert>
							</Grid>
						) : (
							''
						)}

						<div
							className={[
								classes.newsletterActions,
								showCountrySelector ? 'showCountrySelector' : ''
							].join(' ')}
						>
							{showCountrySelector ? (
								<Select
									value={country}
									onChange={event => setCountry(event.target.value)}
									classes={{
										select: classes.newsletterSelect
									}}
									className={classes.newsletterSelectWrapper}
									variant={'outlined'}
									size={'small'}
									autoWidth={true}
								>
									{countries
										.sort((a, b) => {
											if (a.iso < b.iso) {
												return -1;
											}

                                        if (a.iso > b.iso) {
                                            return 1;
                                        }
                                    }).map((country, key) => <MenuItem key={'cntr-key-'+key} value={country.iso}>{country.translated?.name}</MenuItem>)}
                                </Select>
                                )
                            : ''}

                            <TextField
                                type="email"
                                variant={'filled'}
                                size={'small'}
                                label={t('popup:intro-email-placeholder')}
                                className={classes.newsletterInputWrapper}
                                error={!!newsletterEmail && !validateEmail(newsletterEmail)}
                                value={newsletterEmail}
                                onChange={event => setNewsletterEmail(event.target.value)}
                                inputProps={{ className: classes.newsletterInput }}
                                InputProps={{ classes: {root: classes.newsletterInputRoot} }}
                                InputLabelProps={{ className: classes.newsletterInputLabel }}
                            />
                            <Button className={classes.newsletterSignup} onClick={() => newsletterSubmit()}>
                                <>
                                    <ChevronRight width={36} />
                                    {loading ? <CircularProgress size={14} color={'inherit'} style={{ marginLeft: 8 }}/> : ''}
                                </>
                            </Button>
                        </div>

                        <p className={classes.newsletterIntegrity} dangerouslySetInnerHTML={{__html: t('popup:intro-integrity')}} />

                        {success ? <Alert severity={"success"} style={{ marginTop: 10 }}>{t('popup:newsletter-signup-success')}</Alert> : ''}
                    </Grid>

                    <Grid xs={12} sm={6} md={9}  className={classes.links} item>
                        <Grid className={[classes.linksGroupWrapper, menu?.length > 3 ? classes.stackedMenus : ''].join(' ')} container>
                            {menu && menu.map((group, index) =>
                                <Grid xs={12} md={isCheckout ? 8 : Math.round(12 / menu.length)} className={classes.linksGroup} key={index} item>
                                    <Accordion expanded={accordionOpen[index] || isDesktop} className={classes.accordion} square={true}>
                                        <AccordionSummary classes={{expanded: 'expanded', content: 'content'}} className={classes.accordionSummary}>
                                            <Typography className={[classes.linksTitle, accordionOpen[index] && !isDesktop ? 'open' : ''].join(' ')} onClick={(event) => {
                                                let newAccordionOpen = {...accordionOpen};
                                                newAccordionOpen[index] = !accordionOpen[index];

                                                setAccordionOpen(newAccordionOpen);
                                            }} variant="h5">
                                                {group.name}
                                                <ChevronRight width={32} />
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetails}}>
                                            <ul style={{ padding: 0, marginBottom: 0 }}>
                                                {group.children.map((item, index2) => {
                                                    if(!expandLinks[index] && isDesktop && index2 >= 4) {
                                                        return;
                                                    }

                                                    return renderLinkItem(item, index2, index, group.children.length);
                                                })}
                                                {index + 1 === menu.length ?
                                                    <li className={classes.linksItem}>
                                                        <a href="#" onClick={() => Cookiebot.renew()} role="button">
                                                            {t('change-cookie-consent')}
                                                        </a>
                                                    </li> : '' }
                                                {!expandLinks[index] && isDesktop && group.children.length > 4 ? <li className={classes.showMore} onClick={() => {
                                                    let newExpandLinks = { ...expandLinks };

                                                    if(newExpandLinks[index]) {
                                                        newExpandLinks[index] = false;
                                                    } else {
                                                        newExpandLinks[index] = true;
                                                    }

                                                    setExpandLinks(newExpandLinks);
                                                }}>
                                                     <ChevronRight width={32} style={{transform: 'rotate(90deg)'}} />
                                                </li> : ''}
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}

                            <Grid xs={12} md={isCheckout ? 8 : Math.round(12 / menu?.length)} className={[classes.linksGroup, 'desktopOnly'].join(' ')} key={4} item>
                                <Accordion expanded={accordionOpen[4] || isDesktop} className={classes.accordion} square={true}>
                                    <AccordionSummary classes={{expanded: 'expanded', content: 'content'}} className={classes.accordionSummary}>
                                        <Typography className={[classes.linksTitle, accordionOpen[4] && !isDesktop ? 'open' : ''].join(' ')} onClick={(event) => {
                                            let newAccordionOpen = {...accordionOpen};
                                            newAccordionOpen[4] = !accordionOpen[4];

                                            setAccordionOpen(newAccordionOpen);
                                        }} variant="h5">
                                            {t('footer-social-media-title')}
                                            <ChevronRight width={32} />
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        <ul className={classes.socialMediaLinks}
                                            style={{ padding: 0, marginBottom: 0 }}>
                                            <li className={[classes.linksItem, 'socialMedia'].join(' ')}>
                                                <Link href={'https://www.facebook.com/NewportHome'}>
                                                    <a
                                                        onClick={() =>
                                                            trackingActions.click({
                                                                type: 'footer_social',
                                                                text: 'Facebook',
                                                                identifier: 'www.facebook.com/NewportHome'
                                                            })
                                                        }
                                                    >
                                                        {/*<img alt={'Newport at Facebook'} src={'/images/fb.png'} width={18} height={18}/>*/}
                                                        Facebook
                                                    </a>
                                                </Link>
                                            </li>
                                            <li className={[classes.linksItem, 'socialMedia'].join(' ')}>
                                                <Link href={'https://instagram.com/newporthome'}>
                                                    <a
                                                        onClick={() =>
                                                            trackingActions.click({
                                                                type: 'footer_social',
                                                                text: 'Instagram',
                                                                identifier: 'www.instagram.com/newporthome'
                                                            })
                                                        }
                                                    >
                                                        {/*<img alt={'Newport at Instagram'} src={'/images/insta.png'} width={18} height={18}/>*/}
                                                        Instagram
                                                    </a>
                                                </Link>
                                            </li>
                                            <li className={[classes.linksItem, 'socialMedia'].join(' ')}>
                                                <Link href={'https://www.pinterest.se/newporthome'}>
                                                    <a
                                                        onClick={() =>
                                                            trackingActions.click({
                                                                type: 'footer_social',
                                                                text: 'Pinterest',
                                                                identifier: 'www.pinterest.se/newporthome'
                                                            })
                                                        }
                                                    >
                                                        {/*<img alt={'Newport at Pinterest'} src={'/images/pint.png'} width={18} height={18}/>*/}
                                                        Pinterest
                                                    </a>
                                                </Link>
                                            </li>
                                            <li className={[classes.linksItem, 'socialMedia'].join(' ')}>
                                                <Link href={'https://www.tiktok.com/@newporthome'}>
                                                    <a
                                                        onClick={() =>
                                                            trackingActions.click({
                                                                type: 'footer_social',
                                                                text: 'TikTok',
                                                                identifier: 'www.tiktok.com/@newporthome'
                                                            })
                                                        }
                                                    >
                                                        {/*<img alt={'Newport at Pinterest'} src={'/images/pint.png'} width={18} height={18}/>*/}
                                                        TikTok
                                                    </a>
                                                </Link>
                                            </li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            <Container className={classes.socialMediaContainer}>
                <Grid container>
                    <Grid xs={12} md={4} className="desktopOnly" item></Grid>

					<Grid xs={12} md={4} style={{ display: 'flex', justifyContent: 'center' }} item>
						{theme.name === 'xmas' ? (
							<img
                                alt={theme.footerLogo.alt}
                                src={theme.footerLogo.url}
                                style={{ width: '100%', maxWidth: 'min(90%, 375px)', margin: '20px auto 20px auto' }}
								width={375}
								height={123}
							/>
						) : (
							<img
								alt={theme.footerLogo.alt}
								src={theme.footerLogo.url}
								style={{ width: '100%', maxWidth: 125, margin: '20px auto 20px auto' }}
								width={125}
								height={41}
							/>
						)}
					</Grid>

                    <Grid xs={12} md={4} className="desktopOnly" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                          item>
                        <ul style={{ padding: 0, marginBottom: 0 }}>
                            <li className={[classes.linksItem, 'socialMedia'].join(' ')}>
                                <Link href={'https://www.facebook.com/NewportHome'}>
                                    <a
                                        onClick={() =>
                                            trackingActions.click({
                                                type: 'footer_social',
                                                text: 'Facebook',
                                                identifier: 'www.facebook.com/NewportHome'
                                            })
                                        }
                                    >
                                        {/*<img alt={'Newport at Facebook'} src={'/images/fb.png'} width={18} height={18}/>*/}
                                        Facebook
                                    </a>
                                </Link>

                                <Link href={'https://instagram.com/newporthome'}>
                                    <a
                                        onClick={() =>
                                            trackingActions.click({
                                                type: 'footer_social',
                                                text: 'Instagram',
                                                identifier: 'www.instagram.com/newporthome'
                                            })
                                        }
                                    >
                                        {/*<img alt={'Newport at Instagram'} src={'/images/insta.png'} width={18} height={18}/>*/}
                                        Instagram
                                    </a>
                                </Link>

                                <Link href={'https://www.pinterest.se/newporthome'}>
                                    <a
                                        onClick={() =>
                                            trackingActions.click({
                                                type: 'footer_social',
                                                text: 'Pinterest',
                                                identifier: 'www.pinterest.se/newporthome'
                                            })
                                        }
                                    >
                                        {/*<img alt={'Newport at Pinterest'} src={'/images/pint.png'} width={18} height={18}/>*/}
                                        Pinterest
                                    </a>
                                </Link>

                                <Link href={'https://www.tiktok.com/@newporthome'}>
                                    <a
                                        onClick={() =>
                                            trackingActions.click({
                                                type: 'footer_social',
                                                text: 'TikTok',
                                                identifier: 'www.tiktok.com/@newporthome'
                                            })
                                        }
                                    >
                                        {/*<img alt={'Newport at Pinterest'} src={'/images/pint.png'} width={18} height={18}/>*/}
                                        TikTok
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>

			<Container className={classes.copyright} disableGutters>
				<Typography>
					© Newport Collection AB. 2024. VAT number SE556760011801 | Newport Official Online Store
				</Typography>
			</Container>
		</div>
	) : (
		<div className={[classes.wrapper, 'compact'].join(' ')}>
			<img
				alt={theme.footerLogo.alt}
				src={theme.footerLogo.url}
				style={{ width: '100%', maxWidth: 125, margin: '15px auto 5px auto', display: 'block' }}
				width={125}
				height={41}
			/>
			<Container className={classes.copyright} disableGutters style={{ marginBottom: 10 }}>
				<Typography>
					© Newport Collection AB. 2024. VAT number SE556760011801 | Newport Official Online Store
				</Typography>
			</Container>
		</div>
	);
}
